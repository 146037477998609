import React from "react";
import LogoHeader from "../components/logoHeader";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import {
  setFormValue,
  useHandlePhoneForm,
} from "@/lib/features/slices/phoneSlice";
import { PhoneValues } from "@/lib/types/types";
import ReactFlagsSelect from "react-flags-select";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import { CustomLabels } from "react-flags-select/build/types/ReactFlagsSelectTypes";
import countries from "../context/countries.json";
import CustomModal from "../components/customModal";
import Footer from "../components/footer";

const PhoneScreen = () => {
  const store = useAppStore();
  const { values, errors } = useAppSelector((state) => state.phoneFormScreen);
  const handleFieldChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    store.dispatch(setFormValue({ name: name as keyof PhoneValues, value }));
  };
  const handlePhoneForm = useHandlePhoneForm();
  return (
    <div className="wrapper">
      <div className="content">
        <div className="center-page">
          <CustomModal />
          <LogoHeader showBackBtn={true} />

          <form className="form-center">
            <div style={{ height: "150px" }}>
              <p className="text-left mb-2">Phone Number</p>
              <div className="country-selector">
                <ReactFlagsSelect
                  className="!p-0 rfs"
                  selectButtonClassName="!border-none p-0"
                  selected={values.country}
                  onSelect={(code: any) =>
                    store.dispatch(
                      setFormValue({
                        name: "country",
                        value: code,
                      })
                    )
                  }
                  showSecondarySelectedLabel={true}
                  searchable={true}
                  showSelectedLabel={false}
                  customLabels={getCountries().reduce<CustomLabels>(
                    (acc, country) => {
                      acc[country] = {
                        primary:
                          countries.find((c) => c.code === country)?.name ||
                          getCountryCallingCode(country),
                        secondary: country,
                      };
                      return acc;
                    },
                    {}
                  )}
                />

                <input
                  type="tel"
                  name="phone"
                  placeholder="+1 (555) 000-0000"
                  value={values.phone}
                  onChange={handleFieldChange}
                  autoComplete="off"
                />
              </div>
            </div>
          </form>
        </div>
        <Footer singleBtnDark="Done" />
        {/* <button
        className={`send-btn ${values.phone == "" && "disabled-btn"}`}
        onClick={() => handlePhoneForm()}
      >
        Send
      </button> */}
        <div className="resend-code">
          <p>
            Already bought the game?
            <br />
            <span>Download the app now</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhoneScreen;
