import React, { FC } from "react";
import TitleHeader from "./titleHeader";
import { useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import { decremented } from "@/lib/features/slices/screensSlice";
import { useAppDispatch } from "@/lib/hooks";

const LogoHeader: FC<{ showBackBtn?: boolean }> = ({ showBackBtn }) => {
  const screensDetails = useSelector((state: RootState) => state.screenDetails);
  const dispatch = useAppDispatch();
  return (
    <div className="navbar">
      {screensDetails.showLogo === true && (
        <div className="navbar-logo">
          {showBackBtn === true && (
            <button
              onClick={() => dispatch(decremented())}
              className="absolute left-[25px]"
            >
              <img src="/images/bkBtn.svg" alt="logo" />
            </button>
          )}

          <img src="/images/logo.svg" alt="logo" />
        </div>
      )}

      {screensDetails.showHeader && <TitleHeader />}
    </div>
  );
};

export default LogoHeader;
