import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppStore } from "@/lib/hooks";
import { headerData } from "@/lib/features/slices/screensSlice";
import Image from "next/image";
import DayPoster from "../../public/images/day-poster.png";
import { RootState } from "@/lib/store";

const TitleHeader: React.FC = ({}) => {
  const store = useAppStore();
  const screenId = useSelector(
    (state: RootState) => state.screenDetails.screenId
  );
  const screensDetails = useSelector((state: RootState) => state.screenDetails);
  const divs = Array.from({ length: 4 }, (_, index) => (
    <div
      key={index}
      className={`slider ${screenId <= index ? "opacity-20" : ""}`}
    />
  ));

  useEffect(() => {
    store.dispatch(headerData(screenId));
  }, []);

  return (
    <div className="title-header">
      <p className="page-title">{screensDetails.title}</p>
      {screenId == 7 && (
        <Image src={DayPoster} alt="day-poster" style={{ width: "95%" }} />
      )}
      {screensDetails.showDots && (
        <div className="justify-start items-start gap-3 inline-flex">
          {divs}
        </div>
      )}

      <p className="page-desc">
        {screenId === 1 && <p>Already purchased a game?</p>}

        {screensDetails.description}
      </p>
    </div>
  );
};

export default TitleHeader;
